import constants from "./constants";

export default class Helpers { 
    /**
     * 
     * @param {string} rule 
     * @returns bool
     * 
     * Return true if element is an accessory
     */
     isAccessory(rule) {
        if(
            this.isSideBumper(rule) ||
            this.isLadder(rule) ||
            this.isOuterScrewJoint(rule) ||
            this.isInnerScrewJoint(rule) ||
            this.isConnectingPinWithSteel(rule) ||
            this.isPileCage(rule) ||
            this.isMooringDevice(rule) ||
            this.isFence(rule) ||
            this.isWinch(rule) ||
            this.isBathingSlide(rule) 
        ) {
            return true
        }

        return false
    }

    /**
     * 
     * @param {string} rule 
     * @returns bool
     * 
     * Return true if element is considered basic
     */
    isBasicElement(rule) {
        return [
            constants.RULESET_SINGLE_ELEMENT, 
            constants.RULESET_DOUBLE_ELEMENT,
        ].includes(rule)
    }

    /**
     * 
     * @param {string} rule 
     * @returns bool
     * 
     * Return true if element is default connector
     */
     isDefaultConnector(rule) {
        return [
            constants.RULESET_CONNECTING_PIN_SHORT, 
            constants.RULESET_SIDE_SCREW,
        ].includes(rule)
    }

    /**
     * 
     * @param {string} rule 
     * @returns bool
     * 
     * Return true if element is connector
     */
     isConnector(rule) {
        return [
            constants.RULESET_CONNECTING_PIN_SHORT, 
            constants.RULESET_SIDE_SCREW,
            constants.RULESET_CONNECTING_PIN_LONG,
            constants.RULESET_SIDE_SCREW_WITH_NUT_LONG,
            constants.RULESET_SIDE_SCREW_290,
        ].includes(rule)
    }

    /**
     * 
     * @param {string} rule 
     * @returns bool
     * 
     * Return true if element is default connecting pin (connecting pin short)
     */
     isDefaultConnectingPin(rule) {
        return [
            constants.RULESET_CONNECTING_PIN_SHORT, 
        ].includes(rule)
    }

    /**
     * 
     * @param {string} rule 
     * @returns bool
     * 
     * Return true if element is default side screw
     */
     isDefaultSideScrew(rule) {
        return [
            constants.RULESET_SIDE_SCREW,
        ].includes(rule)
    }

    /**
     * 
     * @param {string} rule 
     * @returns bool
     * 
     * Return true if element is considered side bumper
     */
    isSideBumper(rule) {
        return [
            constants.RULESET_SIDE_BUMPER, 
            constants.RULESET_SIDE_BUMPER_3, 
            constants.RULESET_SIDE_BUMPER_4, 
            constants.RULESET_SIDE_BUMPER_5, 
            constants.RULESET_SIDE_BUMPER_6,
            constants.RULESET_SIDE_BUMPER_7,
        ].includes(rule)
    }

    /**
     * 
     * @param {string} rule 
     * @returns bool
     * 
     * Return true if element is considered a ladder
     */
    isLadder(rule) {
        return [
            constants.RULESET_LADDER,
        ].includes(rule)
    }

    /**
     * 
     * @param {string} rule 
     * @returns bool
     * 
     * Return true if element is considered a bathing slide
     */
     isBathingSlide(rule) {
      return [
          constants.RULESET_BATHING_SLIDE,
      ].includes(rule)
  }

    /**
     * 
     * @param {string} rule 
     * @returns bool
     * 
     * Return true if element is considered a outer screw joint
     */
     isOuterScrewJoint(rule) {
        return [
            constants.RULESET_BOAT_CLEAT, 
            constants.RULESET_SCREW_JOINT_UNIT, 
            constants.RULESET_CROSS_BOLLARD_EDGE, 
        ].includes(rule)
    }

    /**
     * 
     * @param {string} rule 
     * @returns bool
     * 
     * Return true if element is considered a inner screw joint
     */
     isInnerScrewJoint(rule) {
        return [
            constants.RULESET_BOAT_MOUNTING, 
            constants.RULESET_CROSS_BOLLARD_PIN, 
            constants.RULESET_GUIDING_SLEEVE_UMBRELLAS, 
            constants.RULESET_FLAG_POLE, 
        ].includes(rule)
    }

    /**
     * 
     * @param {string} rule 
     * @returns bool
     * 
     * Return true if element is considered a coonecting pin with steel
     */
     isConnectingPinWithSteel(rule) {
        return [
            constants.RULESET_CONNECTING_PIN_WITH_STEEL, 
        ].includes(rule)
    }

    /**
     * 
     * @param {string} rule 
     * @returns bool
     * 
     * Return true if element is pile cage (outer)
     */
     isPileCage(rule) {
        return [
            constants.RULESET_PILE_CAGE_0,
            constants.RULESET_PILE_CAGE,
            constants.RULESET_PILE_CAGE_3,
        ].includes(rule)
    }

    /**
     * 
     * @param {string} rule 
     * @returns bool
     * 
     * Return true if element mooring device
     */
     isMooringDevice(rule) {
        return [
            constants.MOORING_DEVICE_0,
            constants.MOORING_DEVICE_2,
            constants.MOORING_DEVICE_3,
        ].includes(rule)
    }

    /**
     * 
     * @param {string} rule 
     * @returns bool
     * 
     * Return true if element is a fence
     */
     isFence(rule) {
        return [
            constants.STANCHION_CORNER,
            constants.STANCHION_WITH_BRACE,
            constants.STANCHION,
            constants.STANCHION_WITH_ARC,
            constants.STANCHION_INSIDE,
        ].includes(rule)
    }

    /**
     * 
     * @param {string} rule 
     * @returns bool
     * 
     * Return true if element is a corner fence
     */
     isCornerFence(rule) {
        return [
            constants.STANCHION_CORNER,
        ].includes(rule)
    }

    /**
     * 
     * @param {string} rule 
     * @returns bool
     * 
     * Return true if element is a winch
     */
     isWinch(rule) {
      return [
          constants.WINCH,
      ].includes(rule)
  }

    /**
     * 
     * @param {string} rule 
     * @returns bool
     * 
     * Return true if element is overlaying
     */
    isOverlayingElement(rule) {
        if(this.isFence(rule) || this.isConnectingPinWithSteel(rule) || this.isLadder(rule) || this.isBathingSlide(rule)) {
            return true
        }

        return false
    }

    /**
     * 
     * @param {string} rule 
     * @returns bool
     * 
     * Return true if element is connector acting like element
     */
     isElementActingLikeConnector(rule) {
        if(this.isOuterScrewJoint(rule) || this.isInnerScrewJoint(rule) || this.isWinch(rule)) {
            return true
        }

        return false
    }

    /**
     * 
     * @param {*} pixiElement 
     * @param {int} rotation 
     * @param {int} boxSize 
     * @param {string} rule 
     * @param {int} width 
     * @param {int} height 
     * 
     * General helper to help draw / redraw current pixi element
     */
    offsetPixiElement(pixiElement, rotation, boxSize, rule, width = 1, height = 1) {
        if(this.isBasicElement(rule)) {
            pixiElement.position.x -= 8;
            pixiElement.position.y -= 8;
            pixiElement.angle = 0;
            pixiElement.anchor.set(0, 0);
            pixiElement.scale.set(1.2,1.2);
        }

        if(this.isSideBumper(rule) || this.isMooringDevice(rule)) {  
            this.handleRotationSideBumper(pixiElement, rotation, boxSize);
            pixiElement.scale.set(1,1);
        }

        if(this.isLadder(rule)) {
            pixiElement.width = width * boxSize;
            pixiElement.height = height * boxSize;
            pixiElement.angle = rotation * 90;
            pixiElement.position.x += 37; // TODO maginc numbers 
            pixiElement.position.y += 37; // TODO maginc numbers 
            pixiElement.anchor.set(0.5,0.5);
            pixiElement.scale.set(0.3,0.3);
        }

        if(this.isOuterScrewJoint(rule) || this.isConnector(rule)) {
            pixiElement.anchor.set(0,0);
            pixiElement.position.x -= 10; 
            pixiElement.position.y -= 10; 
        }

        if(this.isInnerScrewJoint(rule) || this.isConnectingPinWithSteel(rule) || this.isWinch(rule)) {
            pixiElement.scale.set(1,1);
            pixiElement.anchor.set(0.5,0.5);
        }

        if(this.isFence(rule)) {
            this.handleRotationFence(pixiElement, rotation, boxSize, rule)
            pixiElement.scale.set(1,1);
        }

        if(this.isPileCage(rule)) {
            this.handleRotationPileCage(pixiElement, rotation, boxSize, rule)
            pixiElement.scale.set(1,1);
        }

        if(this.isBathingSlide(rule)) {
          this.handleRotationBathingSlide(pixiElement, rotation, boxSize, rule)
          pixiElement.scale.set(1,1);
      }
    }

    handleRotationBathingSlide(pixiElement, rotation, boxSize) {
      this.handleRotation(pixiElement, rotation)

      switch (rotation) {
        case 0: { // left
          pixiElement.position.x -= 0;
          pixiElement.position.y -= 0;
          break;
        }
        case 1: { // top
          pixiElement.position.x += 0;
          pixiElement.position.y += boxSize;
          break;
        }
        case 2: { // right
          pixiElement.position.x += boxSize;
          pixiElement.position.y += 0;
          break;
        }
        case 3: { // down
          pixiElement.position.x -= 0;
          pixiElement.position.y += 0;
          break;
        }
    }
    }

    handleRotationPileCage(pixiElement, rotation, boxSize, rule) {
        this.handleRotation(pixiElement, rotation)

        if(rule == constants.RULESET_PILE_CAGE_0) {
            switch (rotation) {
                case 0: { // left
                  pixiElement.position.x -= 0;
                  pixiElement.position.y -= 0;
                  break;
                }
                case 1: { // top
                  pixiElement.position.x += 0;
                  pixiElement.position.y += boxSize-boxSize - 0;
                  break;
                }
                case 2: { // right
                  pixiElement.position.x += boxSize-boxSize + 0;
                  pixiElement.position.y += 0;
                  break;
                }
                case 3: { // down
                  pixiElement.position.x -= 0;
                  pixiElement.position.y += 0;
                  break;
                }
            }
        }

        switch (rotation) {
            case 0: { // left
              pixiElement.position.x -= 0;
              pixiElement.position.y -= 0;
              break;
            }
            case 1: { // top
              pixiElement.position.x += 0;
              pixiElement.position.y += boxSize - 0;
              break;
            }
            case 2: { // right
              pixiElement.position.x += boxSize + 0;
              pixiElement.position.y += 0;
              break;
            }
            case 3: { // down
              pixiElement.position.x -= 0;
              pixiElement.position.y += 0;
              break;
            }
        }
    }

    handleRotationFence(pixiElement, rotation, boxSize, rule) {
        this.handleRotation(pixiElement, rotation)

        if(rule == constants.STANCHION_WITH_BRACE || rule == constants.STANCHION_INSIDE || rule == constants.STANCHION_WITH_ARC) {
            switch (rotation) {
                case 0: { // left
                  pixiElement.position.x -= 0;
                  pixiElement.position.y -= 0;
                  break;
                }
                case 1: { // top
                  pixiElement.position.x += 0;
                  pixiElement.position.y += boxSize - 0;
                  break;
                }
                case 2: { // right
                  pixiElement.position.x += boxSize + 0;
                  pixiElement.position.y += 0;
                  break;
                }
                case 3: { // down
                  pixiElement.position.x -= 0;
                  pixiElement.position.y += 0;
                  break;
                }
            }
        }

        if(rule == constants.STANCHION) {
            this.handleRotationAndCenter(pixiElement, rotation)
        }

        if(rule == constants.STANCHION_CORNER) {
            switch (rotation) {
                case 0: { // left
                  pixiElement.position.x -= 10;
                  pixiElement.position.y -= 10;
                  break;
                }
                case 1: { // top
                  pixiElement.position.x += 10;
                  pixiElement.position.y += boxSize - 10;
                  break;
                }
                case 2: { // right
                  pixiElement.position.x += boxSize + 10;
                  pixiElement.position.y += 10;
                  break;
                }
                case 3: { // down
                  pixiElement.position.x -= 10;
                  pixiElement.position.y += 10;
                  break;
                }
            }
        }
        
    }

    handleRotationSideBumper(pixiElement, rotation, boxSize) {
        this.handleRotation(pixiElement, rotation)
        switch (rotation) {
            case 0: { // left
              pixiElement.position.x -= 8;
              pixiElement.position.y -= 43;
              break;
            }
            case 1: { // top
              pixiElement.position.x -= 43;
              pixiElement.position.y += boxSize - 8;
              break;
            }
            case 2: { // right
              pixiElement.position.x += boxSize + 8;
              pixiElement.position.y -= 43;
              break;
            }
            case 3: { // down
              pixiElement.position.x -= 43;
              pixiElement.position.y += 8;
              break;
            }
        }
    }

    handleRotation(pixiElement, rotation) {
        pixiElement.angle = rotation * 90;
        switch (rotation) {
          case 0: { // left
            pixiElement.anchor.set(0, 0);
            break;
          }
          case 1: { // top
            pixiElement.anchor.set(1, 1);
            break;
          }
          case 2: { // right
            pixiElement.anchor.set(0, 1);
            break;
          }
          case 3: { // down
            pixiElement.anchor.set(1, 0);
            break;
          }
        }
    }

    handleRotationAndCenter(pixiElement, rotation) {
        pixiElement.angle = rotation * 90;
        switch (rotation) {
          case 0: { // left
            pixiElement.anchor.set(0.5, 0.5);
            break;
          }
          case 1: { // top
            pixiElement.anchor.set(1, 1);
            break;
          }
          case 2: { // right
            pixiElement.anchor.set(0, 1);
            break;
          }
          case 3: { // down
            pixiElement.anchor.set(1, 0);
            break;
          }
        }
    }
}

