import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history', //removes # from url
    base: process.env.VUE_APP_URL_PREFIX,
    routes: [
        {
            path: '/error',
            name: 'api-error',
            component: () => import('../views/ApiError.vue')
        },
        {
            path: '/',
            name: 'home',
            component: () => import('../views/Home.vue')
        },
        {
            path: '/basic-data',
            name: 'basic-data',
            component: () => import('../views/BasicData.vue')
        },
        {
            path: '/configurator',
            name: 'configurator',
            component: () => import('../views/ConfiguratorV3.vue')
        },
        {
            path: '/project-data',
            name: 'project-data',
            component: () => import('../views/ProjectData.vue')
        },
        {
            path: '/thank-you',
            name: 'thank-you',
            component: () => import('../views/ThankYou.vue')
        }
    ]  
});

router.beforeEach((to, from, next) => {
    document.title = 'Pragmabytes Configurator';
    return next();
});



export default router;