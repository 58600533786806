export default class GridPoint {
    x = null;
    y = null;
    level = null;
    overlay = null;

    constructor(x, y, level = 0) {
        this.x = x;
        this.y = y;
        this.level = level;
    }

    move(x, y) {
        return new GridPoint(this.x + x, this.y + y, this.level);
    }

    getNeighbourPoints() {
        let points = [];
        points.push(new GridPoint(this.x - 1, this.y - 1, this.level)); // Levo zgori
        points.push(new GridPoint(this.x + 1, this.y - 1, this.level)); // Levo zgori
        points.push(new GridPoint(this.x, this.y - 1, this.level)); // Zgoraj
        points.push(new GridPoint(this.x + 1, this.y, this.level)); // Desno
        points.push(new GridPoint(this.x - 1, this.y, this.level)); // Levo
        points.push(new GridPoint(this.x, this.y + 1, this.level)); // Spodaj
        points.push(new GridPoint(this.x + 1, this.y + 1, this.level)); // Desno spodi
        points.push(new GridPoint(this.x - 1, this.y + 1, this.level)); // Levo spodi
        return points;
    }

    getNeighbourSquaresAroundPoint() {
        let points = [];
        points.push(new GridPoint(this.x, this.y, this.level)); // self
        points.push(new GridPoint(this.x - 1, this.y, this.level)); // Desno
        points.push(new GridPoint(this.x, this.y - 1, this.level)); // Spodaj
        points.push(new GridPoint(this.x - 1, this.y - 1, this.level)); // Levo spodi
        return points;
    }

    // Neighbours of the location user clicks to place a sidebumper
    getSidebumperNeighbourSquares(rotation, width, height) {
        let points = [];

        // If height and width are 1 element will still take 2 connections so 1 space must be between
        switch (rotation) { // CONSTANT IMPORT FAILED 
            case 0: //constants.ORIENTATION_LEFT:
            case 2: //constants.ORIENTATION_RIGHT:
                points.push(new GridPoint(this.x, this.y + (1 * height), this.level)); // Down 
                points.push(new GridPoint(this.x, this.y - (1 /*height == 1 ? 1 : 0*/), this.level)); // Up
                break;
            case 1: //constants.ORIENTATION_TOP:
            case 3: //constants.ORIENTATION_BOTTOM:
                points.push(new GridPoint(this.x + (1 * width), this.y, this.level)); // Rigth
                points.push(new GridPoint(this.x - (1 /*width == 1 ? 1 : 0*/), this.y, this.level)); // Left
                break;
        }

        return points;
    }

    getSidebumperNeighbourSquaresForConnection(rotation) {
        let points = [];

        switch (rotation) { // CONSTANT IMPORT FAILED 
            case 0: //constants.ORIENTATION_LEFT:
                points.push(new GridPoint(this.x - 1, this.y, this.level)); // Left
                points.push(new GridPoint(this.x - 1, this.y - 1, this.level)); // Left and down
                break;
            case 1: //constants.ORIENTATION_TOP:
                points.push(new GridPoint(this.x - 1, this.y - 1, this.level)); // Up and left
                points.push(new GridPoint(this.x, this.y - 1, this.level)); // Up
                break;
            case 2: //constants.ORIENTATION_RIGHT: // When placing on the right no movement neded as all points are right oriented
                points.push(new GridPoint(this.x, this.y, this.level)); // Self
                points.push(new GridPoint(this.x, this.y - 1, this.level)); // Self and up
                break;
            case 3: //constants.ORIENTATION_BOTTOM:
                points.push(new GridPoint(this.x, this.y , this.level)); // Self
                points.push(new GridPoint(this.x -1 , this.y, this.level)); // Back
                break;
        }

        return points;
    }

    toString() { 
        return `${this.x}x${this.y}-${this.level}`;
    }
}
