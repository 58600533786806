export default class GridSize {
    width = null;
    height = null;

    constructor(width = null, height = null) {
        this.width = width;
        this.height = height;
    }

    getAreaPoints(startPoint) {
        let points = []
        for (let x = 0; x < this.width; x++) {
            points.push(startPoint.move(x, 0))
        }
        for (let y = 1; y < this.height; y++) {
            points.push(startPoint.move(0, y))
        }
        return points;
    }

    rotate() {
        return new GridSize(this.height, this.width);
    }
}
