import axios from 'axios'
import globals from '../../globals'
import router from '../../router';

const initialState = () => ({
    status: '',
    sector: null,
    sectorApplication: null,
    vendor: null,
    projectName: null,
    projectDescription: null,
    projectCountry: null,
    projectConditions: {
        waterType: null,
        waveHeight: null,
        waterDepthMin: null,
        waterDepthMax: null,
        tidalRange: null,
        waterFlowRate: null,
        windSpeed: null,
        underwaterAnchoringRequired: null,
        underwaterSubstrate: '',
        shoreAnchoringRequired: null,
        shoreSubstrate: '',
        loadMax: null,
        gateway: {
            required: null,
            length: null,
            width: null,
        }     
    },
    customer: {
        type: null,
        country: null,
        company: null,
        street: null,
        postalCode: null,
        place: null,
        name: null,
        role: null,
        phone: null,
        email: null,
    },
    attachments: [],
    levelsFiles: [],
    levelsPictures: {},
    products: {},
    configuration: null,
    
    //INTERNAL 
    mode: 'new',
    refNumber: null,
    refId: null,
    pictures: [],

    //sectorpicker
    selectedSector: null,
    selectedSectorApplication: null,

    //VALIDATION STUFF
    validatingProjectDataForm: false,
    validatingCustomerDataForm: false,
    validatingProject: false,
    validationErrors: null
})

const getters = {
    getSelectedSector(state) {
        return state.selectedSector
    },
    getSelectedSectorApplication(state) {
        return state.selectedSectorApplication
    },
    getSector(state) {
        return state.sector
    },
    getSectorApplication(state) {
        return state.sectorApplication
    },
    getVendor(state) {
        return state.vendor
    },
    getProjectName(state) {
        return state.projectName
    },
    getProjectDescription(state) {
        return state.projectDescription
    },
    getProjectCountry(state) {
        return state.projectCountry
    },
    getProjectConditionsWaterType(state) {
        return state.projectConditions.waterType
    },
    getProjectConditionsWaveHeight(state) {
        return state.projectConditions.waveHeight
    },
    getProjectConditionsWaterDepthMin(state) {
        return state.projectConditions.waterDepthMin
    },
    getProjectConditionsWaterDepthMax(state) {
        return state.projectConditions.waterDepthMax
    },
    getProjectConditionsTidalRange(state) {
        return state.projectConditions.tidalRange
    },
    getProjectConditionsWaterFlowRate(state) {
        return state.projectConditions.waterFlowRate
    },
    getProjectConditionsWindSpeed(state) {
        return state.projectConditions.windSpeed
    },
    getProjectConditionsUnderwaterAnchoringRequired(state) {
        return state.projectConditions.underwaterAnchoringRequired
    },
    getProjectConditionsUnderwaterSubstrate(state) {
        return state.projectConditions.underwaterSubstrate
    },
    getProjectConditionsShoreAnchoringRequired(state) {
        return state.projectConditions.shoreAnchoringRequired
    },
    getProjectConditionsShoreSubstrate(state) {
        return state.projectConditions.shoreSubstrate
    },
    getProjectConditionsLoadMax(state) {
        return state.projectConditions.loadMax
    },
    getProjectConditionsGatewayRequired(state) {
        return state.projectConditions.gateway.required
    },
    getProjectConditionsGatewayLength(state) {
        return state.projectConditions.gateway.length
    },
    getProjectConditionsGatewayWidth(state) {
        return state.projectConditions.gateway.width
    },
    getCustomerType(state) {
        return state.customer.type
    },
    getCustomerCountry(state) {
        return state.customer.country
    },
    getCustomerCompany(state) {
        return state.customer.company
    },
    getCustomerStreet(state) {
        return state.customer.street
    },
    getCustomerPostalCode(state) {
        return state.customer.postalCode
    },
    getCustomerPlace(state) {
        return state.customer.place
    },
    getCustomerName(state) {
        return state.customer.name
    },
    getCustomerRole(state) {
        return state.customer.role
    },
    getCustomerPhone(state) {
        return state.customer.phone
    },
    getCustomerEmail(state) {
        return state.customer.email
    },
    getAttachments(state) {
        return state.attachments
    },
    getLevelsFiles(state) {
        return state.levelsFiles
    },
    getProducts(state) {
        return state.products
    },
    getConfiguration(state) {
        return state.configuration
    },
    getLevelsPictures(state) {
        return state.levelsPictures
    },
    getPictures(state) {
        return state.pictures
    },
    getValidatingProjectDataForm(state) {
        return state.validatingProjectDataForm
    },
    getValidatingCustomerDataForm(state) {
        return state.validatingCustomerDataForm
    },
    getValidatingProject(state) {
        return state.validatingProject
    },
    getValidationErrors(state) {
        return state.validationErrors
    },
    getMode(state) {
        return state.mode
    },
    getRefNumber(state) {
        return state.refNumber
    },
    getRefId(state) {
        return state.refId
    },
    hasErrors: (state) => (key) => {
        if(state.validationErrors && (key in state.validationErrors))
            return true;
        else 
            return false
    }
};

const mutations = {
    setSelectedSector(state, newValue) {
        state.selectedSector = newValue
    },
    setSelectedSectorApplication(state, newValue) {
        state.selectedSectorApplication = newValue
    },
    setSector(state, newValue) {
        state.sector = newValue
    },
    setSectorApplication(state, newValue) {
        state.sectorApplication = newValue
    },
    setVendor(state, newValue) {
        state.vendor = newValue
    },
    setProjectName(state, newValue) {
        state.projectName = newValue
    },
    setProjectDescription(state, newValue) {
        state.projectDescription = newValue
    },
    setProjectCountry(state, newValue) {
        state.projectCountry = newValue

        //set customer country
        state.customer.country = newValue

        //if user is loged in assue his vendor country is the customer country
        //TODO
    },
    setProjectConditionsWaterType(state, newValue) {
        state.projectConditions.waterType = newValue
    },
    setProjectConditionsWaveHeight(state, newValue) {
        state.projectConditions.waveHeight = newValue
    },
    setProjectConditionsWaterDepthMin(state, newValue) {
        state.projectConditions.waterDepthMin = newValue
    },
    setProjectConditionsWaterDepthMax(state, newValue) {
        state.projectConditions.waterDepthMax = newValue
    },
    setProjectConditionsTidalRange(state, newValue) {
        state.projectConditions.tidalRange = newValue
    },
    setProjectConditionsWaterFlowRate(state, newValue) {
        state.projectConditions = newValue
    },
    setProjectConditionsWindSpeed(state, newValue) {
        state.projectConditions.waterFlowRate = newValue
    },
    setProjectConditionsUnderwaterAnchoringRequired(state, newValue) {
        state.projectConditions.underwaterAnchoringRequired = newValue
    },
    setProjectConditionsUnderwaterSubstrate(state, newValue) {
        state.projectConditions.underwaterSubstrate = newValue
    },
    setProjectConditionsShoreAnchoringRequired(state, newValue) {
        state.projectConditions.shoreAnchoringRequired = newValue
    },
    setProjectConditionsShoreSubstrate(state, newValue) {
        state.projectConditions.shoreSubstrate = newValue
    },
    setProjectConditionsLoadMax(state, newValue) {
        state.projectConditions.loadMax = newValue
    },
    setProjectConditionsGatewayRequired(state, newValue) {
        state.projectConditions.gateway.required = newValue
    },
    setProjectConditionsGatewayLength(state, newValue) {
        state.projectConditions.gateway.length = newValue
    },
    setProjectConditionsGatewayWidth(state, newValue) {
        state.projectConditions.gateway.width = newValue
    },
    setCustomerType(state, newValue) {
        state.customer.type = newValue
    },
    setCustomerCountry(state, newValue) {
        state.customer.country = newValue
    },
    setCustomerCompany(state, newValue) {
        state.customer.company = newValue
    },
    setCustomerStreet(state, newValue) {
        state.customer.street = newValue
    },
    setCustomerPostalCode(state, newValue) {
        state.customer.postalCode = newValue
    },
    setCustomerPlace(state, newValue) {
        state.customer.place = newValue
    },
    setCustomerName(state, newValue) {
        state.customer.name = newValue
    },
    setCustomerRole(state, newValue) {
        state.customer.role = newValue
    },
    setCustomerPhone(state, newValue) {
        state.customer.phone = newValue
    },
    setCustomerEmail(state, newValue) {
        state.customer.email = newValue
    },
    setAttachments(state, newValue) {
        state.attachments = newValue
    },
    setLevelsFiles(state, newValue) {
        state.levelsFiles = newValue
    },
    setLevelsFile(state, newValue) {
        state.levelsFiles.push(newValue)
    },
    setProducts(state, newValue) {
        state.products = newValue
    },
    setConfiguration(state, newValue) {
        state.configuration = newValue
    },
    setLevelsPictures(state, newValue) {
        state.levelsPictures = newValue
    },
    setLevelsPicture(state, { key, value }) {
        state.levelsPictures[key] = value
    },
    setPictures(state, newValue) {
        state.pictures = newValue
    },
    setValidatingProjectDataForm(state, newValue) {
        state.validatingProjectDataForm = newValue
    },
    setValidatingCustomerDataForm(state, newValue) {
        state.validatingCustomerDataForm = newValue
    },
    setValidatingProject(state, newValue) {
        state.validatingProject = newValue
    },
    setValidationErrors(state, newValue) {
        state.validationErrors = newValue
    },

    setProjectConditions(state, conditions) {
        state.projectConditions.waterType = conditions.water_type
        state.projectConditions.waveHeight = conditions.wave_height
        state.projectConditions.waterDepthMin = conditions.water_dept_min
        state.projectConditions.waterDepthMax = conditions.water_dept_max
        state.projectConditions.tidalRange = conditions.tidal_range
        state.projectConditions.waterFlowRate = conditions.water_flow_rate
        state.projectConditions.underwaterAnchoringRequired = conditions.underwater_anchoring_required
        state.projectConditions.underwaterSubstrate = conditions.underwater_substrate
        state.projectConditions.shoreAnchoringRequired = conditions.shore_anchoring_required
        state.projectConditions.shoreSubstrate = conditions.shore_substrate
        state.projectConditions.loadMax = conditions.load
        state.projectConditions.gateway.required = conditions.gateway
        state.projectConditions.gateway.length = conditions.gateway_length
        state.projectConditions.gateway.width = conditions.gateway_width
    },
    setCustomer(state, customer) {
        state.customer.type = customer.type
        state.customer.country = customer.country
        state.customer.company = customer.company
        state.customer.street = customer.street
        state.customer.postalCode = customer.postal_code
        state.customer.place = customer.place
        state.customer.name = customer.name
        state.customer.role = customer.role
        state.customer.phone = customer.phone
        state.customer.email = customer.email
    },

    setMode(state, newValue) {
        state.mode = newValue
    },
    setRefNumber(state, newValue) {
        state.refNumber = newValue
    },
    setRefId(state, newValue) {
        state.refId = newValue
    },

    reset(state) {
        const s = initialState()
        Object.keys(s).forEach(key => {
            state[key] = s[key]
        })
    },

    removeValidationErrorForProjectName(state) {
        delete state.validationErrors['projectName']
    },  
};

const actions = {
    removeErrorKey({ state }, key) {
        if(state.validationErrors && (key in state.validationErrors)) {
            delete state.validationErrors[key]
        } 
    },
    async validateProjectData({ state }) {
        this.commit('projectData/setValidatingProjectDataForm', true)
        //setTimeout(continueExecution, 10000) localy for candy
        await axios.post(globals.api.general.app.validation.projectData, state)
            .then(() => {
                this.commit('projectData/setValidatingProjectDataForm', false)
                //clear any errors from before
                this.commit('projectData/setValidationErrors', null)
            })
            .catch((error) => {
                //there was an  error show the form again 
                this.commit('projectData/setValidatingProjectDataForm', false) 

                if (error.response.status == 422) {
                    //set validation errors
                    this.commit('projectData/setValidationErrors', error.response.data.errors)   
                } else {
                    //api error redirect user
                    router.push({ name: 'api-error' })
                }
                //something went wrong in the api
                console.log(error.response)
            });
    },

    async validateCustomerDetails({ state }) {
        this.commit('projectData/setValidatingCustomerDataForm', true)
        //setTimeout(continueExecution, 10000) localy for candy
        await axios.post(globals.api.general.app.validation.customerData, state)
            .then(() => {
                this.commit('projectData/setValidatingCustomerDataForm', false)
                //clear any errors from before
                this.commit('projectData/setValidationErrors', null)
            })
            .catch((error) => {
                //there was an error show the form again 
                this.commit('projectData/setValidatingCustomerDataForm', false)
                if (error.response.status == 422) {
                    //set validation errors
                    this.commit('projectData/setValidationErrors', error.response.data.errors)
                } else {
                    //api error redirect user
                    router.push({ name: 'api-error' })
                }
                //something went wrong in the api
                console.log(error.response)
            });
    },

    async validateAndSubmitProject({ state, commit }) {
        this.commit('projectData/setValidatingProject', true)
        state.configuration = JSON.stringify(state.configuration)

        let attachments = [];

        //upload all attachemnts
        for (let i = 0; i < state.attachments.length; i++){
            
            let formData = new FormData();
            formData.append('file', state.projectFiles[i]);
            formData.append('type', 'attachment') //tell the server this is not a level image

            await axios({
                method: 'post',
                url: globals.api.general.app.uploadFile,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                attachments.push(response.data.data);
            })
            .catch(error => {
                Promise.reject(error);
            })
        }

        //upload all level images
        for (let i = 0; i < state.pictures.length; i++){
            let formData = new FormData();
            formData.append('file', state.pictures[i]);
            formData.append('type', 'level_image') //tell the server this is a level image

            await axios({
                method: 'post',
                url: globals.api.general.app.uploadFile,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                commit('setLevelsFile', response.data.data)
                if (state.pictures[i].name.includes('level')) {
                    let name = state.pictures[i].name.split('.')
                    commit('setLevelsPicture', { key: name[1], value: response.data.data.name })
                }
            })
            .catch(error => {
                Promise.reject(error);
            })
        }

        await axios({
            method: 'POST',
            url: state.mode == "new" ? globals.api.general.app.submitProject : `${globals.api.general.app.updateProject}/${state.refId}`, 
            data: state
        }).then(() => {
                this.commit('projectData/setValidatingProject', false)
                //clear any errors from before
                this.commit('projectData/setValidationErrors', null)
            })
            .catch((error) => {
                //there was an error show the form again 
                this.commit('projectData/setValidatingProject', false)
                if (error.response.status == 422) {
                    //set validation errors
                    this.commit('projectData/setValidationErrors', error.response.data.errors)
                } else {
                    //api error redirect user
                    router.push({ name: 'api-error' })
                }
                //something went wrong in the api
                console.log(error.response)
            });
    },
    
};

//const methods = {};


export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};