<template>
  <div class="language-picker-container">
    <v-menu bottom origin="center center" transition="scale-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn 
          filled
          rounded
          dense
          height="36px" 
          v-bind="attrs" 
          v-on="on" 
          class="language-picker-button"
        >
          <v-icon color="white">mdi-earth</v-icon>
          {{ $t(`header.languagePicker.title`) }}
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="locale in locales"
          :key="locale"
          @click="switchLocale(locale)"
        >
          <v-list-item-title class="flag-and-text">
            <country-flag :country="localeToFlag(locale)" size='normal'/>
            {{ $t(`general.locales.languages.${locale}`) }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag'

export default {
  name: 'LocalePicker',

  components: {
    CountryFlag,
  },
  data () {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
    }
  },

  methods: {
    switchLocale (locale) {      
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale
      }
    },
    localeToFlag(locale) {
      let flag = '';
      switch (locale) {
        case 'en-us':
            flag = 'gb'
            break;
        case 'de-de':
        case 1:
            flag = 'de'
            break;
      }

      return flag
    }
  },
}
</script>
<style lang="scss" scoped>
  .language-picker-container {
    width: 150px;
    height: auto;
    display: flex;
    align-items: center;
  }

  .language-picker-button {
    color: white !important;
    width: auto;
    background-color: #004996 !important;
  }
</style>